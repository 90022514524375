import { defineStore } from 'pinia';
import proctoringModel from "../models/proctoring";
import loadScript from "../helpers/loadScript";

async function setupProctoring(store, token, server) {
  await loadScript(`${server}/sdk/supervisor.js`);
  store.supervisor = new window.Supervisor({
    url: server,
  });
  await store.supervisor.init({
    provider: 'jwt',
    token,
  });
  await store.supervisor.start();
  store.isReady = true;
}

export const useProctoringStore = defineStore('proctoring', {
  state: () => ({
    supervisor: null,
    token: null,
    server: null,
    sectionId: null,
    isReady: false,
  }),
  actions: {
    async init() {
      if (!window._STATE.proctoring.enabled) {
        return;
      }
      this.sectionId = window._STATE.proctoring.sectionId;
      this.server = window._STATE.proctoring.server;
      const { enabled, token } = await proctoringModel.getSessionToken(this.sectionId);
      if (!enabled) {
        return;
      }
      this.token = token;
      await setupProctoring(this, this.token, this.server);
    },

    async start(sectionId) {
      const { enabled, token, server } = await proctoringModel.getSessionToken(sectionId);
      if (!enabled) {
        return;
      }
      this.token = token;
      this.sectionId = sectionId;
      this.server = server;
      await setupProctoring(this, this.token, this.server);
      await proctoringModel.startSession(this.sectionId);
    },

    async finish(external = false) {
      if (!external) {
        await this.supervisor.stop();
      }
      await this.supervisor.logout();
      await proctoringModel.finishSession(this.sectionId);
      this.isReady = false;
      this.sectionId = null;
      this.token = null;
    },
  },
})
