<template>
  <fixed-button
    :width="width"
    :height="height"
    :appear-at="appearAt"
    :is-visible="scrolling"
    @click.native="scrollToTop"
  >
    <i :style="iconStyles" class="fa fa-chevron-up fa-fw"></i>
  </fixed-button>
</template>

<script>
  import FixedButton from "./FixedButton";
  export default {
    name: "BackToTopButton",
    components: {FixedButton},
    props: {
      appearAt: {type: Number, default: 300}
    },
    data() {
      return {
        scrolling: false,
        width: 50,
        height: 50
      }
    },
    computed: {
      iconStyles() {
        return {
          'font-size': `${(Math.min(this.width, this.height) / 2)}px`,
          color: '#1946ba'
        }
      }
    },
    methods: {
      scrollToTop: function () {
        let position = document.body.scrollTop || document.documentElement.scrollTop;

        let scrollAnimation = null;
        if (position) {
          window.scrollBy(0, -Math.max(1, Math.floor(position / 10)));
          scrollAnimation = setTimeout(() => {
            this.scrollToTop()
          }, 10);
        } else {
          clearTimeout(scrollAnimation);
        }
      }
    },
    mounted() {
      const scrollDetection = () => {
        const y = window.scrollY;
        this.scrolling = y >= this.appearAt;
      };

      window.addEventListener("scroll", scrollDetection);
    }
  }
</script>
