<template>
  <fixed-button is-visible :width="width" :height="height">
    <i :style="iconStyles" class="fa fa-envelope fa-fw"></i>
    <span class="message-count badge" v-if="messageCount">{{ messageCount }}</span>
  </fixed-button>
</template>

<script>
import FixedButton from "./FixedButton";
import socket from "../../models/socket";
import commonModel from "../../models/common";
import userModel from "../../models/user";
import messages from "../../models/messages";
export default {
  name: "MessagesButton",
  components: {FixedButton},
  data() {
    return {
      width: 50,
      height: 50,
      messageCount: 0,
      notificationSound: new Audio('/message.mp3')
    }
  },
  computed: {
    iconStyles() {
      return {
        'font-size': `${(Math.min(this.width, this.height) / 2)}px`,
        color: '#1946ba'
      }
    }
  },
  async created() {
    this.messageCount = commonModel.getMessagesCount();
    this.onChatsViewed = async () => {
      this.messageCount = await messages.getUnseenCount();
    }
    this.onChatUpdated = async (newMessages) => {
      this.messageCount = await messages.getUnseenCount();
      if (newMessages[0].userId !== userModel.getCurrentUser().id) {
        await this.notificationSound.play();
      }
    }
  },
  mounted()
  {
    if(socket)
    {
      socket.on('chatsViewed', this.onChatsViewed);
      socket.on('chatUpdated', this.onChatUpdated);
    }
  },
  unmounted()
  {
    if(socket)
    {
      socket.off('chatsViewed', this.onChatsViewed);
      socket.off('chatUpdated', this.onChatUpdated);
    }
  },
}
</script>

<style scoped>
  .message-count {
    position: absolute;
    bottom: 4px;
    right: 4px;
    background-color: #d9534f;
    color: #fff;
  }
</style>
