import loader from '../helpers/loader';

export default {
  isRegisteredUser()
  {
    return !!this.getCurrentUser();
  },
  isAdmin()
  {
    return typeof window !== 'undefined' ? window._STATE.staff : false;
  },
  getCurrentUser()
  {
    return typeof window !== 'undefined' ? window._STATE.user : null;
  },
  async getProfile()
  {
    return await loader.getJSON('/user/profile');
  },
  async updatePassword(oldPassword, newPassword)
  {
    return await loader.postJSON('/user/setpassword', { oldPassword, newPassword });
  },
  async updateProfile(data, reasons)
  {
    return await loader.postJSON('/user/setprofile', { data, reasons });
  },
  async sendRecoveryLink(email)
  {
    return await loader.postJSON('/user/sendrecoverylink', { email: email });
  },
  async sendConfirmLink(user, email, lang)
  {
    return await loader.postJSON('/user/sendconfirmlink', {user: user, email: email, code: lang});
  },
  async checkConfirmKey(key)
  {
    return await loader.postJSON('/user/checkconfirmkey', { key: key });
  },
  async passwordRecovery(key, password)
  {
    return await loader.postJSON('/user/passwordrecovery', { key: key, password: password });
  },
  async checkRecoveryKey(key)
  {
    return await loader.postJSON('/user/checkrecoverykey', { key: key });
  },
  async getAllParticipantFieldTypes(visibleOnly = false)
  {
    return await loader.getJSON(`/api/participantfields${visibleOnly ? '?visible=1' : ''}`);
  },
  async getParticipantFieldTypeCategories()
  {
    return await loader.getJSON(`/api/participant-field-categories`);
  },
  async getCustomParticipantFieldTypes()
  {
    return await loader.getJSON('/api/participantfields?custom=1');
  },
  async updateParticipantFields(data, reasons)
  {
    return await loader.postJSON('/api/user/participantfields', { data, reasons });
  },
  async updateParticipantInstitutions(users, institutionId)
  {
    return await loader.postJSON('/api/setusersinstitutions', {users: users, institutionId: institutionId})
  },
  async absorbInstitution(institutionId, targetId)
  {
    return await loader.postJSON('/api/absorbinstitution', {institutionId: institutionId, targetId: targetId})
  },
  async mergeInstitutions(institutionId, targetIds)
  {
    return await loader.postJSON('/api/mergeinstitutions', {institutionId: institutionId, targetIds: targetIds})
  },
  async checkOldPassword(oldPassword)
  {
    return await loader.postJSON('/user/checkoldpassword', {oldPassword: oldPassword})
  },
  async prepareMassRegister(fileId, createStuff)
  {
    return await loader.postJSON('/api/mass-register/prepare', { fileId, createStuff })
  },
  async executeMassRegister(data, createStuff)
  {
    return await loader.postJSON('/api/mass-register/execute', { data, createStuff })
  },
  async addUsersToGroup(users, groupId)
  {
    return await loader.postJSON('/user/adduserstogroup', { users: users, groupId: groupId });
  },
  async removeUserFromGroup(userId, groupId)
  {
    return await loader.postJSON('/user/removeuserfromgroup', { userId: userId, groupId: groupId });
  },
  async confirmRegistration(key)
  {
    return await loader.postJSON(`/api/UserParticipant/confirmations/${key}`);
  },
  async logoutFromAllSessions(userId)
  {
    return await loader.postJSON(`/api/User/${userId}/logout-all`);
  },
  async attachParticipantToStaff(userId)
  {
    return await loader.postJSON(`/api/Staff/${userId}/attach-participant`);
  },
  async mergeEntity(locationId, targetIds, entityType)
  {
    return await loader.postJSON('/api/merge-entity', {entityId: locationId, targetIds: targetIds, entityType: entityType});
  },
}
