import loader from '../helpers/loader';
import _ from 'lodash';

export default {
  async getIndoorsEvent(id)
  {
    return await loader.getJSON(`/api/indoors/${id}`);
  },

  async getIndoorsStatsLinkedToStatsSection(sectionId)
  {
    return await loader.getJSON(`/api/indoors/stats/linked/${sectionId}`);
  },

  async getIndoorsEventForRegister(id)
  {
    return await loader.getJSON(`/api/indoors/${id}/for-register`);
  },

  async getIndoorsEventBySection(id)
  {
    return await loader.getJSON(`/api/indoors/by-section/${id}`);
  },

  async register({ id, placeId, sections, stream, participantFields })
  {
    return await loader.postJSON(`/api/indoors/${id}/register`, { placeId, sections, stream, participantFields });
  },

  async confirm({ id, sectionId })
  {
    return await loader.postJSON(`/api/indoors/${id}/confirm/${sectionId}`);
  },

  async getIndoorsParticipants(id, sectionId)
  {
    return await loader.getJSON(`/api/indoors/${id}/participants/${sectionId}`);
  },

  async getIndoorsAllParticipants()
  {
    return await loader.getJSON(`/api/indoors/participants/all`);
  },

  async getIndoorsCandidates(id, sectionId)
  {
    return await loader.getJSON(`/api/indoors/${id}/candidates/${sectionId}`);
  },

  async getRequestStatus(id, sectionId)
  {
    return await loader.getJSON(`/api/indoors/${id}/${sectionId}/check`);
  },

  async getIndoorsByParticipant(userId)
  {
    return await loader.postJSON(`/api/indoors/streams-by-participant/`, { userId });
  }
}
