import loader from "../helpers/loader";

export default {
  async getSessionToken(sectionId) {
    return await loader.postJSON(`/api/proctoring/start`, { sectionId });
  },

  async startSession(sectionId) {
    return await loader.postJSON(`/api/proctoring/started`, { sectionId });
  },

  async finishSession(sectionId) {
    return await loader.postJSON(`/api/proctoring/stopped`, { sectionId });
  }
}
