import moment from 'moment';
import momentTimezone from 'moment-timezone/builds/moment-timezone.min.js';
import Vue from 'vue';

const tzPacked = typeof window !== 'undefined' ? window._STATE.timezone : null;
if(tzPacked)
{
  const tz = moment.tz.add(tzPacked);
  moment.tz.setDefault(moment.tz.names()[0]);
}

Vue.filter('date', function(val, format = 'L LT')
{
  return val ? moment(val).format(format) : '';
});
