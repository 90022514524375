import { defineStore } from 'pinia';
import userModel from '../models/user';
import indoorsModel from "../models/indoors";
import notificationsModel from "../models/notifications";
import _ from "lodash";

export const useProfileStore = defineStore('profile', {
  state: () => ({
    profile: {},
    notifications: [],
    streams: [],
    fieldTypes: [],
  }),
  actions: {
    async loadProfile() {
      this.profile = await userModel.getProfile();
    },
    async loadStreams() {
      if (this.profile.id) {
        this.streams = await indoorsModel.getIndoorsByParticipant(this.profile.id);
      }
    },
    async loadNotifications() {
      this.notifications = await notificationsModel.getAvailableNotifications();
    },
    async loadFieldTypes() {
      let fieldTypes = await userModel.getAllParticipantFieldTypes(true);
      this.fieldTypes = _.keyBy(fieldTypes, 'key');
    }
  },
})
