export default function loadFile({ allowedExtensions = ['jpg', 'jpeg', 'png'], mode = 'dataUrl', maxSize = 0 })
{
  let input = document.createElement('input');
  input.type = 'file';
  input.accept = allowedExtensions ? allowedExtensions.map(ext => `.${ext}`).join(', ') : '*';
  input.setAttribute('style', 'visibility:hidden;');
  document.body.appendChild(input);
  setTimeout(() => input.click(), 0);
  return new Promise((resolve, reject) =>
  {
    input.onchange = function()
    {
      let actions = [];
      for(let file of this.files)
      {
        if(!file)
          return;
        actions.push(readFile(file, { allowedExtensions, mode, maxSize }));
      }
      Promise.all(actions).then(resolve)
      .catch((e) =>
      {
        alert(e.message);
        reject(e);
      });
    };
  });
}

function readFile(file, { allowedExtensions, mode, maxSize })
{
  return new Promise((resolve, reject) =>
  {
    try
    {
      let reader = new FileReader();
      reader.onload = (e) =>
      {
        try
        {
          let ext = file.name.split('.');
          ext = ext[ext.length-1].toLowerCase();
          const correctExtension = !allowedExtensions || allowedExtensions.includes(ext);
          const correctSize = maxSize <= 0 || file.size <= maxSize;
          if (!correctExtension)
            throw new Error('Invalid file extension');
          else if (!correctSize)
            throw new Error('File is too big');
          else {
            let data = reader.result;
            let content = data;
            resolve({ name: file.name, data: content });
          }
        }
        catch(e)
        {
          reject(e)
        }
      };
      if(mode === 'dataUrl')
        reader.readAsDataURL(file);
      else if(mode === 'text')
        reader.readAsText(file);
    }
    catch(e)
    {
      reject(e);
    }
  })
}
