import loader from '../helpers/loader';

export default {
  getCurrentLang()
  {
    return typeof window !== 'undefined' ? (window._STATE.lang || 'ru') : 'ru';
  },
  getLangs()
  {
    return typeof window !== 'undefined' ? window._STATE.langs : [];
  },
  getMessagesCount()
  {
    return typeof window !== 'undefined' ? window._STATE.messageCount : 0;
  },
  async setLang(code)
  {
    return await loader.postJSON('/user/setlang', { code });
  },
  async findTimezones(countryCode)
  {
    return await loader.getJSON(`/register/${countryCode}/timezones`);
  },
  async setLanguageStatus(code, statusId)
  {
    return await loader.postJSON(`/api/language/status`, { code, statusId });
  },
  async setMainLanguage(code)
  {
    return await loader.postJSON(`/api/language/main/`, { code });
  },
  async saveTranslation(translation)
  {
    return await loader.postJSON(`/api/language/save-translation/`, { translation })
  },
  async restoreDefaultTranslation(code)
  {
    return await loader.postJSON(`/api/language/restore-translation/`, { code })
  },
  async getStorages()
  {
    return await loader.getJSON('/api/files/storages');
  }
}
