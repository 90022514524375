<template>
  <alert :value="isOutdated" dismissable type="warning" class="top-margin">
    <h4 class="alert-heading">{{ $t('browser.notSupportedMessage') }}</h4>
    <p>
      ({{ $t('browser.guide.part1') }} {{ browserName }}
      {{ browserVersion }} {{ $t('browser.guide.part2') }})
      <a href="https://browser.yandex.ru/" target="_blank">{{ $t('browser.guide.yandex') }}</a>.
    </p>
  </alert>
</template>

<script>
  import Bowser from 'bowser';
  import Alert from './Alert';

  export default {
    name: "OutdatedBrowserAlert",
    components: { Alert },
    data: () => ({
      isOutdated: false,
      browserName: '',
      browserVersion: ''
    }),
    created() {
      const browser = Bowser.getParser(window.navigator.userAgent);
      this.browserName = browser.getBrowserName();
      this.browserVersion = browser.getBrowserVersion();
      this.isOutdated = !browser.satisfies({
        edge: '>=44',
        opera: '>=50',
        safari: '>=11.3',
        chrome: '>=63',
        chromium: '>=63',
        yandex: '>=1',
        firefox: '>=58',
        googlebot: '>=1',
        google_search: '>=1'
      });
    }
  }
</script>
