require('isomorphic-fetch');

class Error404 extends Error
{
  constructor()
  {
    super('Error 404');
  }
}

class TimeoutError extends Error
{
  constructor()
  {
    super('Request timed out');
  }
}

const fetchWithTimeout = (url, data, timeout) => {
  return new Promise((resolve, reject) => {
    // Set timeout timer
    const timer = setTimeout(
      () => reject(new TimeoutError()),
      timeout
    );

    fetch(url, data).then(
      response => resolve(response),
      err => reject(err)
    ).finally(() => clearTimeout(timer));
  });
}

const getCustomHeaders = () => {
  let headers = {};

  if(window._STATE.user && window._STATE.user.id)
    headers['X-User-Id'] = window._STATE.user.id;

  if(window._STATE.serversideKey)
    headers['X-Serverside-Key'] = window._STATE.serversideKey;

  return headers;
}


export default {
  async getJSON(url, includeCustomHeaders = true)
  {
    const headers = includeCustomHeaders ? getCustomHeaders() : {};

    const res = await fetch(url, {
      credentials: 'same-origin',
      headers
    });

    if(res.status == 404)
      throw new Error404();
    // console.log(res.json());
    return res.json();
  },

  async postJSON(url, data, includeCustomHeaders = true)
  {
    const headers = {
      'Content-Type': 'application/json',
      ...(includeCustomHeaders ? getCustomHeaders() : {})
    };

    const res = await fetchWithTimeout(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers,
      credentials: 'same-origin',
    }, 120000);

    if(res.status >= 300 && res.status < 400 || res.url.endsWith('login'))
    {
      alert('Время сессии истекло');
      throw new Error('Auth error');
    }

    if(res.status === 403)
    {
      alert('Доступ запрещен');
      throw new Error('Auth error');
    }

    if(res.status == 404)
      throw new Error404();

    const result = await res.json();

    if(result.error)
      throw new Error(result.error);

    return result;
  },

  async getContent(url)
  {
    const res = await fetch(url, {
      credentials: 'same-origin'
    });

    if(res.status == 404)
      throw new Error404();

    return res.text();
  },

  Error404,
}
