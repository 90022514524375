import Md from 'markdown-it';
import Toc from 'markdown-it-toc';
import Katex from 'markdown-it-katex';

const md = new Md({
  html: true
});
md.use(Toc);
md.use(Katex);

// [video materialId]
const Video = (md) =>
{
  const REGEXP = /^\[video ([^\]]*)\]$/i;
  const video = (state, silent) =>
  {
    const match = REGEXP.exec(state.src);
    if(!match || match.length < 2 || silent)
        return false;

    let token = state.push('video_body', '', 0);
    token.content = match[1];

    let offset = 0;
    let newline = state.src.indexOf('\n');
    if(newline !== -1)
      offset = state.pos + newline;
    else
      offset = state.pos + state.posMax + 1;

    state.pos = offset;
    return true;
  };

  md.renderer.rules.video_body = (tokens, index) =>
  {
    const src = tokens[0].content;
    return `
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="/media-frame/${src}" allowfullscreen="true"></iframe>
      </div>
    `;
  };

  md.inline.ruler.before('image', 'video', video);
};

// [audio materialId]
const Audio = (md) =>
{
  const REGEXP = /^\[audio ([^\]]*)\]$/i;
  const audio = (state, silent) =>
  {
    const match = REGEXP.exec(state.src);
    if(!match || match.length < 2 || silent)
      return false;

    let token = state.push('audio_body', '', 0);
    token.content = match[1];

    let offset = 0;
    let newline = state.src.indexOf('\n');
    if(newline !== -1)
      offset = state.pos + newline;
    else
      offset = state.pos + state.posMax + 1;

    state.pos = offset;
    return true;
  };

  md.renderer.rules.audio_body = (tokens, index) =>
  {
    const src = tokens[0].content;
    return `
      <div class="embed-responsive" style="height: 3em">
        <iframe class="embed-responsive-item" src="/media-frame/${src}"></iframe>
      </div>
    `;
  };

  md.inline.ruler.before('image', 'audio', audio);
};

const ReplaceImagesSource = (md) => {
  const replaceAttr = (token, attrName) => {
    token.attrs.forEach(attr => {
      if (attr[0] === attrName && attr[1][0] === '/') {
        attr[1] = `${window._STATE.cdn}${attr[1]}`;
      }
    });
  };

  const replacer = (state) => {
    state.tokens.forEach(blockToken => {
      if (blockToken.type === 'inline' && blockToken.children) {
        blockToken.children.forEach(token => {
          const type = token.type;
          if (type === 'image') {
            replaceAttr(token, 'src')
          }
        });
      }
    });
    return false;
  }

  md.core.ruler.after('inline', 'replace-link', replacer);
}

md.use(Video);
md.use(Audio);

if (window._STATE.cdn) {
  md.use(ReplaceImagesSource);
}

md.renderer.rules.table_open = (tokens, idx) => '<table class="table">';

export default {
  render: (text) => md.render(text)
}
