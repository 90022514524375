<template>
  <div class="fixed-block">
    <messages-route v-if="isLoggedIn && areMessagesEnabled" v-show="showMessages"/>
    <div class="fixed-buttons-block">
      <back-to-top-button/>
      <messages-button v-if="isLoggedIn && areMessagesEnabled" @click.native="messagesClick"/>
    </div>
  </div>
</template>

<script>
import BackToTopButton from "./BackToTopButton";
import MessagesButton from "./MessagesButton";
import MessagesRoute from "./MessagesRoute";
import userModel from "../../models/user";
export default {
  name: "BottomRightBlock",
  components: {
    MessagesRoute,
    MessagesButton,
    BackToTopButton
  },
  data: () => ({
    showMessages: +window.localStorage.getItem('showMessages') || false
  }),
  computed: {
    isLoggedIn() {
      return userModel.getCurrentUser() !== null;
    },
    areMessagesEnabled() {
      return window._STATE.areMessagesEnabled;
    }
  },
  methods: {
    messagesClick() {
      this.showMessages = !this.showMessages;
      window.localStorage.setItem('showMessages', +this.showMessages);
    }
  }
}
</script>

<style scoped lang="scss">
.fixed-block {
  z-index: 1;
  display: flex;
  position: fixed;
  bottom: 50px;
  right: 50px;
}

.fixed-buttons-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 10px;

  & > * {
    margin-top: 10px;
  }
}
</style>
