import moment from 'moment';
import Vue from 'vue';

moment.relativeTimeThreshold('d', 30);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('ss', 0);
moment.updateLocale('en', {
  relativeTime: {
    m: 'minute',
    h: 'hour',
    d: 'day',
    ss: 'second(-s)'
  }
});
moment.updateLocale('ru', {
  relativeTime: {
    ss: "секунд(-ы)"
  }
});

Vue.filter('duration', function (time) {
  return `${time.days ? (time.days === 1 ? '1 ' : '') + moment.duration(time.days, "days").humanize() + ' ' : ''}
          ${time.hours ? (time.hours === 1 ? '1 ' : '') + moment.duration(time.hours, "hours").humanize() + ' ' : ''}
          ${time.minutes ? (time.minutes === 1 ? '1 ' : '') + moment.duration(time.minutes, "minutes").humanize() + ' ' : ''}
          ${time.seconds ? time.seconds + ' ' + moment.duration(time.seconds, "seconds").humanize() : ''}`;
});
