import Vue from 'vue';

const isElementUnderScroll = (el, offsetTop = 0) => {
  const rect = el.getBoundingClientRect();
  return rect.top - offsetTop >= 0;
}

Vue.directive('sticky-block', {
  inserted(el, { value = {} } = {})
  {
    const checkVisibility = () => {
      const p = el.parentNode;
      if(!isElementUnderScroll(p, value.offsetTop || 0))
      {
        p.style.height = p.offsetHeight+'px';

        if(value.preserveWidth)
          el.style.width = p.offsetWidth-(+value.hPadding || 0)+'px';
        el.classList.add('sticky');
      }
      else
        el.classList.remove('sticky');
    }

    document.addEventListener('scroll', checkVisibility);
    window.addEventListener('resize', checkVisibility);
  },
  unbind()
  {
    // document.removeEventListener('scroll', this.checkVisibility);
    // window.removeEventListener('resize', this.checkVisibility);
  }

});
