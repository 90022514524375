import _ from 'lodash';
import io from 'socket.io-client/dist/socket.io.js';
import user from './user';

let socket = null;
if(user.isRegisteredUser())
{
  socket = io(location.host, { transports: ['websocket', 'polling']});

  socket.on('connect', () => {
    socket.emit('init', { id : user.getCurrentUser().id });
  });
}

export default socket;
