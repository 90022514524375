<template>
  <transition name="fade">
    <button :style="buttonStyles"
            class="btn-fixed"
            v-if="isVisible">
      <slot></slot>
    </button>
  </transition>
</template>

<script>

export default {
  name: "FixedButton",
  props: {
    width: {type: Number, default: 50},
    height: {type: Number, default: 50},
    appearAt: {type: Number, default: 300},
    isVisible: {type: Boolean, required: true}
  },
  computed: {
    buttonStyles() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`
      }
    }
  }
}
</script>

<style scoped>
.btn-fixed {
  border: 2px solid #1946ba;
  padding: 0;
  background: #fff;
  cursor: pointer;
  color: #333;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  z-index: 1;
}

.btn-fixed:hover {
  background: #f7f7f7;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .1s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
